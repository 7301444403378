<template>
<div>
    <div id="fake-container"></div>
    <div class="content">

        <!-- DOCUMENT VIEW -->
        <div id="document-view">
            <template class="pdf-document">
                <div class="doc-container">
                    <b-link @click.prevent="closePDF">
                        <div id="close-pdf">
                            <font-awesome-icon icon="times-circle"></font-awesome-icon>
                        </div>
                    </b-link>
                    <b-link @click.prevent="$bvModal.show('emailaddress-modal')">
                        <div id="send-pdf">
                            <font-awesome-icon icon="envelope"></font-awesome-icon>
                        </div>
                    </b-link>
                    <div id="progress-container" v-if="showprogress">
                        <div id="progress-message">{{ progressmessage }}</div>
                        <div id="progress-background"></div>
                    </div>
                    <div id="page-counter-container">
                        <div id="page-counter" v-if="pages">
                            <div id="page-count">{{ subpage }} / {{ count }}</div>
                        </div>
                    </div>
                    <div v-if="this.pageerror">
                        <div id="page-error">{{ pageerror }}</div>
                    </div>
                    <div id="pages" v-if="pages">
                        <div :id="'page-'+page.pageNumber" v-for="(page, index) in pages" v-bind:key="index">
                            <img class="pdf-page" :src="page.url" :onload="notePageIsFullyLoaded(true)" />
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <!-- SEND PDF MODAL -->
        <b-modal no-fade id="emailaddress-modal">
            <template v-slot:modal-header="">
                <b-col>
                    <b-row align-h="center">
                        Provide member's email address:
                    </b-row>
                    <!-- MESSAGES -->
                    <b-row id="errormsg" v-if="docserror" align-h="center" class=" mb-0 max-100">
                        <b>{{ docserror }}</b>
                    </b-row>
                    <b-row id="docsmsg" v-if="docsmsg" align-h="center" class=" mb-0 max-100">
                        <b>{{ docsmsg }}</b>
                    </b-row>
                </b-col>


            </template>

            <template v-slot:default="">
                <div>
                    <b-form-input id="email-address" v-model="emailaddress"></b-form-input>

                </div>
            </template>

            <template v-slot:modal-footer="">
                <b-container fluid>
                    <a class="send-pdf-link" href="#" @click.prevent="sendPDF">
                        <div class="send-pdf-button">Send Document</div>
                    </a>
                </b-container>

            </template>

        </b-modal>



    </div>


</div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

library.add(faTimesCircle, faEnvelope)

export default {
    name: 'docs',

    data() {
        return {
            allowcheckpageready: true,
            pages: null,
            pagesloaded: 0,
            scale: 2,
            emailaddress: '',
            prefix_two_digit: false,
            runningprogresscheck: false,
            pageschecked: 0,
            progressmessage: 'Preparing file for use...',
            progressbackground: '#00ff003d',
            progresspercentage: '2',
            subpage: 1,
            showingpagesnumbers: false,
            pageisfullyloaded: '',
            addingpages: false
        };

    },

    mounted() {
        this.setInitialProgressCheck(true);
        this.setShowProgress(false);
        this.fetchPDF();
    },

    computed: {
        ...mapGetters({
            count: 'documents/count',
            previouspage: 'documents/previouspage',
            docserror: 'documents/docserror',
            docsmsg: 'documents/docsmsg',
            docname: 'documents/docname',
            user: 'auth/user',
            showprogress: 'documents/showprogress',
            pagereadycount: 'documents/pagereadycount',
            initialprogresscheck: 'documents/initialprogresscheck',
            pageerror: 'documents/pageerror'
        }),
    },

    watch: {
        showprogress(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    document.getElementById('progress-background').style.backgroundColor = this.progressbackground;
                    document.getElementById('progress-background').style.width = this.progresspercentage + '%';
                })
                this.runProgressCheck();
            }
        }
    },

    methods: {
        ...mapActions({
            getDoc: 'documents/getDoc',
            sendDoc: 'documents/sendDoc',
            setDocsError: 'documents/setDocsError',
            setDocsMsg: 'documents/setDocsMsg',
            setInitialProgressCheck: 'documents/setInitialProgressCheck',
            setShowProgress: 'documents/setShowProgress',
            setPageError: 'documents/setPageError',
            setPageReadyCount: 'documents/setPageReadyCount',
            turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
            turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg',
        }),

        fetchPDF() {
            this.getDoc().then(() => {
                if (this.pageerror) {
                    // console.log('there was an error')
                    this.turnOffLoadingMsg();
                } else {
                    // console.log('there was no error')
                    if (this.count > 9) {
                        this.prefix_two_digit = true;
                    }
                    this.setPageReadyCount(0);
                    this.pages = [];
                    this.turnOffLoadingMsg();
                    this.runProgressCheck().then(() => {
                        if (this.initialprogresscheck) {
                            this.setInitialProgressCheck(false);
                            if (this.pagereadycount != this.count) {
                                this.setShowProgress(true);
                            }
                        }

                        this.addMorePages();
                    })
                }
            })
        },

        notePageIsFullyLoaded() {
            this.pageisfullyloaded = true;
        },

        showPageNumber() {
            // console.log('scrolling');
            // console.log('x: ' + x + ' y: ' + y);
            var x = Number(window.innerWidth * 0.5);
            var y = Number(window.innerHeight * 0.5);
            var el = document.elementFromPoint(x, y);
            // console.log('el: ' + el)
            if (el.classList.contains('pdf-page')) {
                var arr = el.src.split('/');
                // console.log('arr: ' + arr)
                // console.log('el array: ' + el);
                var arrItem = arr.length - 1;
                this.subpage = Number(arr[arrItem]);
            }
        },

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async waitForImageToLoad() {
            var i = 0;
            while (i < 60) {
                if (this.pageisfullyloaded) {
                    // console.log('loaded')
                    return true;
                } else {
                    // console.log('is not loaded')
                    i++;
                    await this.sleep(50);
                }

            }
            return false;
        },

        async addMorePages() {
            this.addingpages = true;
            var lastload = false;
            var i = this.pagesloaded + 1;
            var j = i + 4;
            if (j > this.count) {
                j = this.count;
            }
            for (i; i <= j; i++) {
                // console.log('Attempting to get page ' + i);
                var pageNumber = (this.prefix_two_digit) ? String(i).padStart(2, '0') : i;

                // console.log('pageNumber: ' + pageNumber);
                var location = axios.defaults.baseURL;
                var hostlink = location.replace('/api', "");
                var imageUrl = hostlink + 'docdownload/' + this.docname + '/' + pageNumber;
                var pageUrl = hostlink + 'documents/pageexists/' + this.docname + '/' + pageNumber;
                // console.log('url: ' + url);
                if (i <= this.pagereadycount) {
                    this.pages.push({ pageNumber: i, url: imageUrl });
                    this.pageisfullyloaded = false;
                    await this.waitForImageToLoad(i).then(() => {
                        this.pagesloaded = i;
                        if (this.pagesloaded == this.count) {
                            // console.log('last load')
                            lastload = true;
                        }
                        if (!lastload && i === j) {
                            this.$nextTick(() => {
                                document.getElementById('pages').removeEventListener('scroll', this.loadMorePages);
                                document.getElementById('pages').addEventListener('scroll', this.loadMorePages);
                            });
                        }
                    })

                } else {
                    var runBreak = false;
                    await this.checkPageReady(pageUrl).then(async (res) => {
                        if (res) {
                            // console.log('is ready')
                            this.pages.push({ pageNumber: i, url: imageUrl });
                            this.pageisfullyloaded = false;
                            await this.waitForImageToLoad(i).then(() => {
                                this.pagesloaded = i;
                                if (this.pagesloaded == this.count) {
                                    // console.log('last load')
                                    lastload = true;
                                }
                            })
                        } else {
                            // console.log('not ready')
                            if (!this.showprogress) {
                                this.setShowProgress(true);
                            }
                            this.$nextTick(() => {
                                document.getElementById('pages').removeEventListener('scroll', this.loadMorePages);
                                document.getElementById('pages').addEventListener('scroll', this.loadMorePages);
                            });
                            runBreak = true;
                        }

                        return;
                    }).then(() => {
                        if (!lastload && i === j) {
                            this.$nextTick(() => {
                                document.getElementById('pages').removeEventListener('scroll', this.loadMorePages);
                                document.getElementById('pages').addEventListener('scroll', this.loadMorePages);
                            });
                        }
                    });
                    if (runBreak) {
                        break;
                    }
                }
            }
            this.addingpages = false;

            if (!this.showingpagesnumbers) {
                if (this.pagesloaded) {
                    this.showingpagesnumbers = true;
                    document.getElementById('pages').addEventListener('scroll', this.showPageNumber);
                }
            }

            return;
        },

        async runProgressCheck() {
            var progressStallWatch = 0;
            var formerPctProgress = 0;
            while (this.showprogress && progressStallWatch <= 3 && formerPctProgress < 1 || this.initialprogresscheck) {
                // console.log('Running progress check...')
                let response = await axios.get('documents/progresscheck/' + this.docname);
                // console.log(response);
                if (response.data.pagetotal) {
                    this.setPageReadyCount(response.data.pagecount);
                    var pctProgress = Number(response.data.pagecount / response.data.pagetotal);
                    if (pctProgress > 0 && !this.initialprogresscheck) {
                        document.getElementById('progress-background').style.width = pctProgress * 100 + '%';
                    }
                    // console.log('Progress: ' + pctProgress + '%');
                }
                if (response.data.errormsg) {
                    this.setShowProgress(false);
                    // console.log(response.data.errormsg);
                }
                if (this.initialprogresscheck) {
                    break;
                }
                if (formerPctProgress != pctProgress) {
                    formerPctProgress = pctProgress;
                    progressStallWatch = 0;
                    if (this.pagesloaded === 0 && !this.addingpages) {
                        this.addMorePages();
                    }
                    if (formerPctProgress === 1) {
                        this.setShowProgress(false);
                        // console.log('breaking')
                        break;
                    }

                } else {
                    progressStallWatch++;

                }
                // console.log('sleeping')
                await this.sleep(2000);

            }
            if (progressStallWatch > 3) {
                document.getElementById('progress-background').style.backgroundColor = '#ff000040';
                document.getElementById('progress-background').style.width = '100%';
                if (this.pagereadycount > 0) {
                    this.progressmessage = 'Problem: file not completely built.';
                } else {
                    this.progressmessage = 'Problem: file build has stalled.';
                }
                // console.log('There is a problem, progress has stalled.')
            }
            return;
        },

        async checkPageReady(pageUrl) {
            // console.log('url: ' + pageUrl);
            let response = await axios.get(pageUrl);
            if (response.data.message === 'wait') {
                if (response.data.pagecount) {
                    this.setPageReadyCount(response.data.pagecount);
                }
                return false;
            }
            if (response.data.message === 'proceed') {
                if (response.data.pagecount) {
                    this.setPageReadyCount(response.data.pagecount);
                }
                return true;
            }

        },



        loadMorePages() {
            // console.log('scrolling...')
            if (this.pagesloaded < this.count && this.pagesloaded > 0) {
                var lastPage = document.getElementById('page-' + Number(this.pagesloaded - 1));
                if (lastPage) {
                    // console.log('scrolling...')
                    if (lastPage.getBoundingClientRect().top < window.innerHeight) {
                        document.getElementById('pages').removeEventListener('scroll', this.loadMorePages);
                        // console.log('Load more pages');
                        this.addMorePages();
                        // console.log('lastPage.top = ' + lastPage.getBoundingClientRect().top);
                        // console.log('screenBottom = ' + window.innerHeight)
                    }
                }
            }
        },

        closePDF() {
            // this.initialprogresscheck = true;
            this.turnOnLoadingMsg().then(() => {
                this.setDocsError(null);
                this.setPageError(null);
                this.setDocsMsg(null);

                var page = this.previouspage;
                this.$router.replace({
                    name: page
                }).then(() => {
                    this.turnOffLoadingMsg();

                }).catch(() => {
                    this.$router.replace({
                        name: 'home'
                    }).then(() => {
                        this.turnOffLoadingMsg();

                    })
                })


            }) //.catch(e => { console.log(e); })

        },

        validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);

        },


        sendPDF() {
            if (this.validateEmail(this.emailaddress)) {
                this.setDocsError('');
                this.setDocsMsg('');

                var params = {
                    'emailaddress': this.emailaddress,
                    'user_id': this.user.user_id
                };

                this.sendDoc(params).then(() => {
                    if (this.docsmsg != '') {
                        this.emailaddress = '';
                    }
                });

            } else {
                this.setDocsError('Invalid email format.');

            }

        },

    },


}
</script>

<style scoped>
#fake-container {
    position: fixed;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0.25em;
    min-width: 100%;
    background-color: #0d2365;
    min-height: 3.5em;
    z-index: 1010;
}

.content {
    background-color: white;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    margin-top: 3.5em;
    width: 100%;
}

#document-view {
    max-width: 100%;
    margin: 0 0 0 0;
    margin-top: 3.5em;
    margin-bottom: 3.5em;
    padding: 0 0 0 0;


}

#close-pdf {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    padding-left: 1em;
    transform: translateY(0.4em);
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    width: 50vw;
    text-align: left;
    z-index: 1020;
}

#send-pdf {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    padding-right: 1em;
    transform: translateY(0.4em);
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    width: 50vw;
    float: right;
    text-align: right;
    z-index: 1020;
}

#progress-container {
    position: fixed;
    top: 0;
    margin-top: 3.5em;
    width: 100%;
    border-bottom: 3px solid black;
    background-color: white;
}

#progress-message,
#page-error {
    position: absolute;
    padding-left: 0.75em;
    padding-top: 1vh;
}

#progress-background {
    position: relative;
    top: 0;
    width: 2%;
    height: 5vh;
    background-color: #00ff003d;
}

#page-counter-container {
    position: absolute;
}

#page-counter {
    position: fixed;
    right: 0.75em;
    margin-top: 0.5em;
    border: 2px solid #0d2365;
    padding-left: 0.75em;
    border-radius: 0.5em;
    background-color: white;
    padding-right: 0.75em;
    color: #0d2365;

}

#pages {
    height: 100vh;
    overflow: scroll;
    pointer-events: auto;
}

#emailaddress-modal {}

#msg-row {
    min-height: 1em;

}

#errormsg {
    margin-bottom: 2vh;
    color: red;
    font-weight: bold;
    min-height: 1em;
}

#docsmsg {
    margin-bottom: 2vh;
    color: #00FA9A;
    font-weight: bold;
    min-height: 1em;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s linear;
}

.send-pdf-link {
    margin: auto;
    text-align: center;
    text-decoration: none;
    outline: none;
}

.send-pdf-button {
    font-weight: bold;
    color: white;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    background-color: #0f70b7;
}

.doc-container {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.pdf-document {
    position: absolute;
    overflow: auto;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.pdf-page {
    max-width: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;

}
</style>
